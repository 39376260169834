import React, { useState } from "react"

/* Import Global Components */
import Form from "~components/form/form"
import Input from "~components/form/input"
import PhoneNumber from "~components/form/phoneNumber"
import Checkbox from "~components/form/checkbox"
import Submit from "~components/form/submit"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./sign-up.css"

const SignUpSlide = () => {
  const [canSubmit, setCanSubmit] = useState(false)

  const disableButton = () => {
    setCanSubmit(false)
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const handleSubmit = data => {}

  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideRight()
    }
  }

  return (
    <Slide title="sign-up">
      <div className="sign-up-container">
        <div>
          <div>
            <p>Welcome to Puppy Love</p>
          </div>
          <div>
            <Form
              handleValidSubmit={handleSubmit}
              handleOnValid={enableButton}
              handleOnInvalid={disableButton}
            >
              <Input placeholder="Name" name="name" validations="hasValues" />
              <Input
                type="email"
                placeholder="Email"
                name="email"
                validations="isEmail"
                required
              />
              <Checkbox
                value={true}
                name="emailUpdates"
                label="Send me updates 1x a month or so"
              />
              <PhoneNumber type="tel" placeholder="Phone" name="phone" />
              <Checkbox
                value={true}
                name="phoneUpdates"
                label="Send me texts every few months"
              />
              <Submit disabled={!canSubmit}>Sign Up</Submit>
            </Form>
          </div>
          <div>
            <button onClick={goToNextSlide}>Not Right Now</button>
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default SignUpSlide
